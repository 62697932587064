import { __decorate } from "tslib";
import { Module, VuexModule, getModule, Mutation, MutationAction } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import store from '@/store';
import { RECORD_HISTORY_MODULE_NAMESPACE } from '@/store/modules/record-history.definitions';
import BackupsApi from '@/services/api/backups.api';
let RecordHistory = class RecordHistory extends VuexModule {
    constructor() {
        super(...arguments);
        this.backup = null;
        this.record = null;
        this.isAccessImmediate = null;
    }
    get recordId() {
        // TODO: add support for index fields
        return this.record.Id?.value;
    }
    setBackup(backup) {
        this.backup = backup;
    }
    setRecord(record) {
        this.record = record;
    }
    setIsAccessImmediate(isAccessImmediate) {
        this.isAccessImmediate = isAccessImmediate;
    }
    async findRecord(payload) {
        const { record, backupId, isAccessImmediate } = await BackupsApi.findRecord({
            recordId: this.recordId || payload.recordId,
            backupId: this.backup.id,
            objectName: payload.objectName,
            organizationId: payload.organizationId,
            mode: payload.mode,
            storageId: payload.storageId,
        });
        const backup = await BackupsApi.findById(backupId);
        return {
            record: record || {},
            backup,
            isAccessImmediate: !!isAccessImmediate,
        };
    }
};
__decorate([
    Mutation
], RecordHistory.prototype, "setBackup", null);
__decorate([
    Mutation
], RecordHistory.prototype, "setRecord", null);
__decorate([
    Mutation
], RecordHistory.prototype, "setIsAccessImmediate", null);
__decorate([
    MutationAction({ rawError: true, mutate: ['backup', 'record', 'isAccessImmediate'] })
], RecordHistory.prototype, "findRecord", null);
RecordHistory = __decorate([
    Module({
        name: RECORD_HISTORY_MODULE_NAMESPACE,
        namespaced: true,
        dynamic: true,
        store,
    })
], RecordHistory);
export const RecordHistoryModule = getModule(RecordHistory);
export const RecordHistoryModuleStore = namespace(RECORD_HISTORY_MODULE_NAMESPACE);
