import { __decorate } from "tslib";
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { BackupRecordSearchMode } from '@flosum-backup/contracts';
import { RecordViewModuleStore } from '@/store/modules/record-view';
import RecordHistoryBlock from '@/components/record-history/RecordHistoryBlock.vue';
import { RecordHistoryModule, RecordHistoryModuleStore } from '@/store/modules/record-history';
let RecordHistoryViewer = class RecordHistoryViewer extends Vue {
    get fields() {
        const fields = new Set([...Object.keys(this.backupObjectRecord), ...Object.keys(this.comparingRecord)]);
        return [...fields].map(name => ({
            name,
            label: this.backupObjectRecord[name]?.label || this.comparingRecord[name]?.label || 'Unknown field',
        }));
    }
    get differenceFields() {
        return this.fields
            .filter(({ name }) => this.backupObjectRecord[name]?.crc !== this.comparingRecord[name]?.crc)
            .map(({ name }) => name);
    }
    created() {
        RecordHistoryModule.setBackup(this.backup);
        RecordHistoryModule.setRecord(this.backupObjectRecord);
        RecordHistoryModule.setIsAccessImmediate(true);
    }
    onHistoryScrolled(from, scrollValue) {
        if (from === 'left') {
            this.rightHistory.enforceScrollTo(scrollValue);
        }
        else {
            this.leftHistory.enforceScrollTo(scrollValue);
        }
    }
    onFieldExpanded(from, [field, height]) {
        if (from === 'left') {
            this.rightHistory.enforceFieldExpand(field, height);
        }
        else {
            this.leftHistory.enforceFieldExpand(field, height);
        }
    }
    async searchRecord(mode) {
        try {
            this.$loading(`Searching record in ${mode === 'next' ? 'new' : 'old'} backups.`);
            await RecordHistoryModule.findRecord({
                mode: mode === 'next' ? BackupRecordSearchMode.Next : BackupRecordSearchMode.Previous,
                objectName: this.backupObject.name,
                organizationId: this.organizationId,
                storageId: this.backupStorage.id,
                recordId: this.backupObjectRecord.Id.value,
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.$loading(false);
        }
    }
};
__decorate([
    Prop()
], RecordHistoryViewer.prototype, "organizationId", void 0);
__decorate([
    RecordViewModuleStore.State
], RecordHistoryViewer.prototype, "backup", void 0);
__decorate([
    RecordViewModuleStore.State
], RecordHistoryViewer.prototype, "backupObject", void 0);
__decorate([
    RecordViewModuleStore.State
], RecordHistoryViewer.prototype, "backupStorage", void 0);
__decorate([
    RecordViewModuleStore.State
], RecordHistoryViewer.prototype, "backupObjectRecord", void 0);
__decorate([
    RecordHistoryModuleStore.State('record')
], RecordHistoryViewer.prototype, "comparingRecord", void 0);
__decorate([
    RecordHistoryModuleStore.State('backup')
], RecordHistoryViewer.prototype, "comparingBackup", void 0);
__decorate([
    RecordHistoryModuleStore.State('isAccessImmediate')
], RecordHistoryViewer.prototype, "comparingIsAccessImmediate", void 0);
__decorate([
    Ref()
], RecordHistoryViewer.prototype, "leftHistory", void 0);
__decorate([
    Ref()
], RecordHistoryViewer.prototype, "rightHistory", void 0);
RecordHistoryViewer = __decorate([
    Component({
        components: { RecordHistoryBlock },
    })
], RecordHistoryViewer);
export default RecordHistoryViewer;
