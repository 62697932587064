import { __decorate } from "tslib";
import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator';
let RecordHistoryBlock = class RecordHistoryBlock extends Vue {
    onScroll(event) {
        const target = event.target;
        return target.scrollTop;
    }
    onExpanded(field, height) {
        return [field, height];
    }
    onNextBackup() { }
    onPreviousBackup() { }
    enforceScrollTo(value) {
        this.scroller.scrollTo({
            top: value,
        });
    }
    enforceFieldExpand(field, height) {
        const fieldRef = this.$refs[`field_${field}`];
        const ref = Array.isArray(fieldRef) ? fieldRef[0] : fieldRef;
        if (ref) {
            ref.enforceHeight(height);
        }
    }
};
__decorate([
    Prop()
], RecordHistoryBlock.prototype, "fields", void 0);
__decorate([
    Prop()
], RecordHistoryBlock.prototype, "record", void 0);
__decorate([
    Prop()
], RecordHistoryBlock.prototype, "backupName", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], RecordHistoryBlock.prototype, "differenceFields", void 0);
__decorate([
    Prop({
        default: false,
    })
], RecordHistoryBlock.prototype, "showNavigation", void 0);
__decorate([
    Prop({
        default: true,
    })
], RecordHistoryBlock.prototype, "isAccessImmediate", void 0);
__decorate([
    Ref()
], RecordHistoryBlock.prototype, "scroller", void 0);
__decorate([
    Emit()
], RecordHistoryBlock.prototype, "onScroll", null);
__decorate([
    Emit()
], RecordHistoryBlock.prototype, "onExpanded", null);
__decorate([
    Emit()
], RecordHistoryBlock.prototype, "onNextBackup", null);
__decorate([
    Emit()
], RecordHistoryBlock.prototype, "onPreviousBackup", null);
RecordHistoryBlock = __decorate([
    Component({})
], RecordHistoryBlock);
export default RecordHistoryBlock;
