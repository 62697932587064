import { __decorate } from "tslib";
import { Component, Vue, Inject } from 'vue-property-decorator';
import RecordHistoryViewer from '@/components/record-history/RecordHistoryViewer.vue';
let BackupObjectRecordHistory = class BackupObjectRecordHistory extends Vue {
    async gotoRecordView() {
        this.$router
            .push({
            name: 'backups.backup.objects.object.records.record',
            query: this.$route.query,
        })
            .catch(() => false);
    }
};
__decorate([
    Inject()
], BackupObjectRecordHistory.prototype, "organizationId", void 0);
BackupObjectRecordHistory = __decorate([
    Component({
        components: { RecordHistoryViewer },
    })
], BackupObjectRecordHistory);
export default BackupObjectRecordHistory;
